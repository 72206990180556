import React from 'react'
import Slidebar from "./Sidebar"
import BigHeader from "./BigHeader"
import { NavLink } from 'react-router-dom';
import lilleLogo from "../../Images/lilleLogo.png"
import stortLogo from "../../Images/stortLogo2.png"

const Header = () => {
    return (
        <section>

            <header>

                <NavLink to="/"><img className="lilleLogo" src={lilleLogo} alt="Gestus Behandling mobil logo"/></NavLink>

                <div className="headerHolder">
                    <NavLink to="/"><img className="stortLogo" src={stortLogo} alt="Gestus Behandling Computer logo"/></NavLink>

                    <BigHeader />
                </div>

                

                <Slidebar className="fullSidebar" /> 
            </header>   
        </section>
    )
}

export default Header
