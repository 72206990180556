import React from 'react'
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"
import * as FaIcons from "react-icons/fa"
import { NavLink } from 'react-router-dom';

function EksamensStresset() {
    return (
        <section>
        <Header/>
            <section className="undersider">
            <h2 className="undersider_h2 gamerH2"><div className="backArrow">
                <NavLink to="/teenage"><FaIcons.FaArrowAltCircleLeft/></NavLink>
            </div>Eksamens stresset</h2>
                    <div className="underside_images">
                        <img src={process.env.PUBLIC_URL + '/Img/Eksamen.jpg'} loading="lazy" alt="En skole computer og 3 boeger oven på" className="underside_img"/>
                    </div>

                <div className="underside_text">
                    <p className="underside_p">Mange teenagere føler sig presset af alle de krav, som der konstant stilles til dem. Det er krav om gode karakter i skolen, krav om at tage sig godt ud på de sociale medier, være populær i vennekredsen og få en masse 12-taller. Det er alle krav, som stresser de unge og får dem til at spænde op i både nakken og hovedet.</p>
                    <p className="underside_p">Gestus Behandling tilbyder derfor en behandling skræddersyet til de unge bestående af: 25 minutters blid massage af ryggen + 35 minutters afstressende Kranio Sakral behandling af nervesystemet samt nakken og hovedet.</p>
                </div>
            </section>
        <Footer/>
    </section>
    )
}

export default EksamensStresset