import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Organmassage() {
    return (
        <section>
            <Header />

            <section className="undersider">
                    <h2 className="undersider_h2">Organmassage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Organmassage.jpg'} loading="lazy" alt="kunde for Organmassage hos Gestus behandling" className="underside_img"/>
                        </div>

                        <div className="underside_text">
                        <p className="underside_p">Organmassage er for dig, som ønsker en blid, men effektiv form for afspænding,
der reducerer og afbalancere spændinger i vævet, der er relateret til kroppens
organer – mave, tyk- og tyndtarm, galdeblærer, lever, nyrer, blære og hjertet.</p>
                        <p className="underside_p">Ved organmassage vil du starte med at modtage en detox behandling af maven,
hvor jeg gennem blid massage sætter gang i dit blod- og lymfekredsløb. Med de
indfølende massageteknikker vil du opleve forløsning af spændinger, knuder og
arvæv i maven samt opleve en øget bevægelsesfrihed til dine tarme.</p>
                        <p className='underside_p'>Organmassage kan bl.a. være med til at afhjælpe:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Lændesmerter, ryg- og nakkespændinger</li>
                            <li className="underside_li">Træthed og stress idet massagen er med til at balancere nervesystemet</li>
                            <li className="underside_li">Besvær med vejrtrækningen - afspænding af åndedrætsmusklen</li>
                            <li className="underside_li">Forbedre fordøjelse og absorption. Afhjælpe refleks og sure opstød</li>
                            <li className="underside_li">Forbedre immunforsvaret. Afhjælpe søvnbesvær og uro</li>
                            <li className="underside_li">Genskabe vitalitet, fysisk og psykisk</li>
                        </ul>
                        <p className='underside_p'>Desuden er det en vigtig bonus, at 80-90% af kroppens serotonin netop
produceres i tarmen.</p>
                        <p className='underside_p'>De fleste af mine klienter oplever allerede efter første behandling en følelse af
velvære og lethed. Men for at opnå en fuld forbedring af ovenstående
problematikker anbefales 3-5 behandlinger.</p>

                    </div>
                </section>


            <Footer />
        </section>
    )
}

export default Organmassage
