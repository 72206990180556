import React from 'react'
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"
import * as FaIcons from "react-icons/fa"
import { NavLink } from 'react-router-dom';

function Gamer() {
    return (
        <section>
        <Header/>
            <section className="undersider">
            <h2 className="undersider_h2 gamerH2"><div className="backArrow">
                <NavLink to="/teenage"><FaIcons.FaArrowAltCircleLeft/></NavLink>
            </div>Gamers</h2>
                    <div className="underside_images">
                        <img src={process.env.PUBLIC_URL + '/Img/Gamer.jpg'} loading="lazy" alt="En teenager som spiller computer" className="underside_img"/>
                    </div>

                <div className="underside_text">
                    <p className="underside_p">Rigtig mange unge bruger dagligt mange timer foran computeren. Computerverden har mange fantastiske egenskaber, men desværre også store belastninger for særligt nakken og lænden efter mange timer i gamerstolen for den unge ryg og nakke.</p>
                    <p className="underside_p">Gestus Behandling tilbyder derfor en behandling målrettet netop til disse udfordringer. Behandlingen består at 30 minutters massage af ryggen og lænden + 30 minutters Kranio Sakral behandling med særligt fokus på nakken og lænden, som belastes af de mange timer foran skærmen.</p>
                </div>
            </section>
        <Footer/>
    </section>
    )
}

export default Gamer