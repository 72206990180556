import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Massage() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Massage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/FysiurgiskMassage2.jpg'} loading="lazy" alt="Dette billede ser man en patient få massage" className="underside_img"/>
                        </div>

                    <div className="underside_text">
                        <p className="underside_p">Ved en fysiurgisk massage ved Gestus Behandling starter vi altid med en samtale om dine smerter og problemstillinger inden vi sammen planlægger det videre forløb. Jeg tilpasser derefter mine behandlinger til de udfordringer vi har valgt fokusset skal dreje sig om. Det kan fx være smerter i ryggen, nakken, lænden, skulderen eller hovedet. Ved nogle smerter skal der udføres en dybdegående massage, hvor jeg tager godt ved og går dybt i muskulaturen, hvorimod andre smerter kræver en blidere og mere afspændende behandling og eventuelt suppleret med teknikker fra Kranio Sakral.</p>
                        <p className="underside_p">En fysiurgisk massage ved Gestus Behandling skal ses som både en behandlende og forebyggende indsats med det formål at afspænde dine muskler og undgå smerter, og er med til at frigive og løsne spændinger, muskelsmerter og/eller fastlåsninger i både muskler, led og væv. Massagen har særlig stor gavn og virkning på smerter som fx:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Hovedpine og migræne</li>
                            <li className="underside_li">Smerter i nakken og skulderen</li>
                            <li className="underside_li">Smerter i ryggen og lænden</li>
                            <li className="underside_li">Smerter i benene og lårene</li>
                            <li className="underside_li">Smerter i ballen – fx fra iskiasnerven</li>
                        </ul>
                    </div>
                </section>
            <Footer/>

        </section>
    )
}

export default Massage
