import React from 'react';
import Sidebar from './Assets/Components/Header/Sidebar';
import Home from "./Assets/Pages/Home"
import Ansigtsbehandling from "./Assets/Pages/Ansigtsbehandling"
import FysiurgiskMassage from "./Assets/Pages/Fysiurgiskmassage"
import Hjemmebehandling from "./Assets/Pages/Hjemmebehandling"
import Kontakt from "./Assets/Pages/Kontakt"
import OmGestus from "./Assets/Pages/OmGestus"
import SaerligeBehov from "./Assets/Pages/SaerligeBehov"
import Priser from "./Assets/Pages/Priser"
import Velveare from "./Assets/Pages/Velveare" 
import KranioSakralTerapi from "./Assets/Pages/KranioSakralTerapi"
import PageNotFound from "./Assets/Pages/PageNotFound"
import KranioSakralBehandling from "./Assets/Pages/KranioSakralBehandling"
import Massage from "./Assets/Pages/Massage"
import Teenager from "./Assets/Pages/Teenage/Teenager"
import Gamer from "./Assets/Pages/Teenage/Gamer"
import EksamensStresset from "./Assets/Pages/Teenage/EksamensStresset"
import Gravidmassage from "./Assets/Pages/Gravidmassage"
import Efterfodselsmassage from "./Assets/Pages/Efterfodselsmassage"
import Babybehandling from "./Assets/Pages/Babybehandling"
import Organmassage from "./Assets/Pages/Organmassage"
import Tilbyder from "./Assets/Pages/Tilbyder"

import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

function App() {
  return (
    
    <div className="App" id="outer-container">
    <BrowserRouter>
    <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/home" component={Home} />
          <Route path="/ansigtsbehandling" component={Ansigtsbehandling} />
          <Route path="/efterfodselsmassage" component={Efterfodselsmassage} />
          <Route path="/gravidmassage" component={Gravidmassage} />
          <Route path="/hjemmebehandling" component={Hjemmebehandling} />
          <Route path="/kontakt" component={Kontakt} />
          <Route path="/teenage" component={Teenager} />
          <Route path="/gamer" component={Gamer} />
          <Route path="/eksamensstresset" component={EksamensStresset} />
          <Route path="/priser" component={Priser} />
          <Route path="/omGestus" component={OmGestus} />
          <Route path="/velveare" component={Velveare} />
          <Route path="/kranioSakralTerapi" component={KranioSakralTerapi} />
          <Route path="/fysiurgiskmassage" component={FysiurgiskMassage}/>
          <Route path="/saerligeBehov" component={SaerligeBehov} />
          <Route path="/kraniosakralbehandling" component={KranioSakralBehandling} />
          <Route path="/fysiurgiskmassagebehandling" component={Massage}/>
          <Route path="/Babybehandling" component={Babybehandling}/>
          <Route path="/Tilbyder" component={Tilbyder}/>
          <Route path="/Organmassage" component={Organmassage}/>
          <Route  component={PageNotFound} />

        </Switch>
    </BrowserRouter>
    </div>


  );
}

export default App;