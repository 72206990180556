import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Priser() {
    return (
        <section>
            <Header />

            <section className="undersiderPriser">
                <h3 className="undersider_h2_Priser">Priser</h3>
                <div className="priserDiv">

                <h4 className="PriserHeadings">
                Priserne er uanset behandlingsform, idet jeg ofte veksler mellem behandlingsformer, for at du opnår det største udbytte og virkning af behandlingen. 
                </h4>

                <ul className="PrisUl">
                    <li className="underside_li">30 minutter: 375 kr.</li>
                    <li className="underside_li">60 minutter: 595 kr.</li>
                    <li className="underside_li">90 minutter: 875 kr.</li>
                </ul>

          
                <ul className="PrisUl">
                <li className="underside_li">Klippekort:</li>
                    <li className="underside_li">3 klip 1695 kr.</li>
                    <li className="underside_li">5 klip 2750 kr.</li>
                </ul>


                <h5 className="PriserHeadings">Tiderne er inkl. samtale og af- og påklædning
                 </h5>
                <h5 className="PriserHeadings">Betalingsform: Kontant eller Mobilepay</h5>


</div>
            </section>

           

            
            <Footer />
        </section>
    )
}

export default Priser
