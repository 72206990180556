import React from 'react'
import Footer from "../Components/Footer/Footer"
import Header from "../Components/Header/Header"

function PageNoteFound() {
    return (
        <section>
            <Header />
                <section className="pageNotFound">
                    <h1 className="pageNotFound_h1">Page not found</h1>
                    <p className="pageNotFound_p">Siden du ville finde blev ikke fundet, prøv noget andet.</p>
                </section>
            <Footer />
        </section>
    )
}

export default PageNoteFound