import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Ansigtsbehandling() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Gravidmassage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Protraet.jpg'} loading="lazy" alt="Dette billede ser man en patient få ansigtsbehandling" className="underside_img"/>
                        </div>
                    <div className="underside_text">
                        <p className="underside_p">Gravidmassage er en afstressende og smertedæmpende massage, der er med til at afspænde hele kroppen, med en vekslende kombination af velvære massage og fysiurgisk massage.</p>
                        <p className="underside_p">Gravidmassage er naturligvis tilpasset den gravides særlige behov for velvære, afspænding og ro, og er særdeles velegnet til at lindre og afhjælpe:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Bækken- og lændesmerter</li>
                            <li className="underside_li">Lægkramper</li>
                            <li className="underside_li">Hovedpine</li>
                            <li className="underside_li">Nakke- og skulderspændinger</li>
                            <li className="underside_li">Stimulerer immunsystemet</li>
                            <li className="underside_li">Øge fordøjelsesprocessen</li>
                            <li className="underside_li">Give bedre søvn</li>
                            <li className="underside_li">Afhjælpe stress og angst</li>
                            <li className="underside_li">Ødemer/væske i ben og hænder</li>
                            <li className="underside_li">Iskiassmerter og spændte baller</li>
                            <li className="underside_li">Reducere muskelspændinger, øge bevægeligeligheden i led og reducere associerede smerter. Fx lænd, ryg og korsben</li>
                        </ul>
                        <p className="underside_p">Gestus Behandling har en særlig graviditetspude med god plads til maven, der gør, at du under behandlingen kan ligge trygt på maven uden at trykke på babyen, men blot nyde behandlingen med fuld fokus på dig, din graviditet og dit velvære.</p>
                       
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default Ansigtsbehandling