import React from 'react'
import { NavLink } from 'react-router-dom';

export const BigHeader = () => {
    return (
        <section className="bigheader">

            <ul>
            <li><NavLink exact to="/"
                    isActive={(_, { pathname }) => ["/", "/home"].includes(pathname)}
                    activeClassName="navbar__link--active"
                    className="navbar__link">Forside</NavLink></li>

                <li>
                    <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/kranioSakralTerapi">
                        Kranio Sakral Terapi
                    </NavLink>
                </li>
                
                <li>
                   <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/fysiurgiskmassage">
                        Fysiurgisk massage
                    </NavLink> 
                </li>
                
                <li>
                    <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/Tilbyder">
                    Jeg tilbyder
                    </NavLink>
                     {/* <div className="dropdown">
                        <button className="dropbtn">Jeg tilbyder</button>
                        <div class="dropdown-content">
                            <NavLink to="/kraniosakralbehandling">Kranio Sakral behandling</NavLink>
                            <NavLink to="/fysiurgiskmassagebehandling">Massage</NavLink>
                            <NavLink to="/Babybehandling">Babybehandling</NavLink>
                            <NavLink to="/teenage">Teenagere</NavLink>
                            <NavLink to="/saerligeBehov">Særlige behov</NavLink>
                            <NavLink to="/ansigtsbehandling">Ansigtsmassage</NavLink>
                            <NavLink to="/velveare">Velvære</NavLink>
                            <NavLink to="/hjemmebehandling">Hjemmebehandling</NavLink>
                            <NavLink to="/gravidmassage">Gravidmassage</NavLink>
                            <NavLink to="/efterfodselsmassage">Efterfødselsmassage</NavLink>

                        </div>
                </div> */}
                </li>

                <li>
                     <NavLink className=" navbar__link" activeClassName="navbar__link--active" to="/kontakt"> 
                        Kontakt
                    </NavLink>
                </li>        
                   
                <li>
                    <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/priser">
                        Priser
                    </NavLink>
                </li>
                    
                <li>
                    <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/omGestus">
                        Om Gestus
                    </NavLink>
               
                </li>
                    
                    </ul>
    
        </section>
    )
}

export default BigHeader
