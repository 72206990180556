import React from 'react'

function OmGEstusComponet() {
    return (

        <section className="undersider">
        <h2 className="undersider_h2">Om Gestus Behandling</h2>
            <div className="underside_images">
            <img src={process.env.PUBLIC_URL + '/Img/Protraet.jpg'} loading="lazy" alt="Protraet af Pia Sejer Lind" className="ProtraetImg"/>
            </div>

        <div className="underside_text">
            <p className="underside_p">Jeg hedder Pia Sejer Lind, er 50 år og uddannet lægeeksamineret fysiurgisk massør, Kranio Sakral Terapi behandler, coach og mentor og derudover er jeg indehaver af Gestus Behandling.

<br/>
 Når jeg behandler dig, er det vigtigt for mig, altid at have en anerkendende, tillidsfuld og afsøgende tilgang, så du føler dig tryg i det øjeblik du kommer ind ad min dør.
 <br/>
 <br/>
 Det er vigtigt for mig, at du altid får en professionel og korrekt behandling, og jeg starter derfor altid med en samtale, om hvad du ønsker jeg skal gøre for dig, og hvordan du ønsker jeg hjælper dig bedst muligt. Sammen planlægger vi det videre forløb, og beslutter hvilken behandlingsform, som giver bedst mening for dig og dine smerter -  Altså, en god Gestus fra starten af.
</p>

        </div>
    </section>



    )
}

export default OmGEstusComponet

