import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Hjemmebehandling() {
    return (
        <section>
            <Header />

            <section className="undersider">
                    <h2 className="undersider_h2">Hjemmebehandling</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/HjemmeBehandling.jpg'} loading="lazy" alt="På dette billede står ejeren af Gestus behandling, med hendes briks i den tilhørende taske. Hun står foran hendes bil på vej til en kunde." className="underside_img"/>
                        </div>

                    <div className="underside_text">
                        <p className="underside_p">I særlige tilfælde kan jeg tilbyde hjemmebehandling. Fx til et bosted, eller ved alvorlige smerter, som gør at du ikke kan komme til mig.</p>
                        <p className="underside_p">Ring venligst for denne behandling.</p>
        
                    </div>
                </section>


            <Footer />
        </section>
    )
}

export default Hjemmebehandling
