import React from 'react'
import * as FaIcons from "react-icons/fa"
import { NavLink } from "react-router-dom"

function Footer() {
    return (
        <section className="footer_container">
            <section className="footer">
                <section className="footer_icons">
                    <a href="https://www.facebook.com/Gestusbehandling" target="blank" alt="Facebooks logo som linker til Gestus behandlings facebook side"><FaIcons.FaFacebookSquare/></a>
                    <a href="https://www.instagram.com/gestusbehandling/" target="blank" alt="Instagrams logo som linker til Gestus behandlings instagram side"><FaIcons.FaInstagramSquare/></a>
                    <a href="tel:+4526436760 " alt="Et symbol af en telfon som ringer op os Gestus behandling"><FaIcons.FaPhoneSquareAlt/></a>
                    <NavLink alt="Et symbol af en mail som liker ind til kontakt" to="/Kontakt"><FaIcons.FaEnvelopeSquare/></NavLink>
                </section>
                <hr className="footer_hr"/>
                <section className="footer_links">
                    <NavLink to="/Kontakt">Kontakt</NavLink>
                    <NavLink to="/velveare">Velvære</NavLink>
                    <NavLink to="/priser">Priser</NavLink>
                    <NavLink to="/omGestus">Om Gestus</NavLink>
                </section>
            </section>
        </section>
    )
}

export default Footer