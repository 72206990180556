import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import KontakCompoent from "../Components/Kontakt/KontakCompoent"
import Map from "../Components/Kontakt/KontaktMap"

function Kontakt() {
    return (
        <section>
            <Header />

            <KontakCompoent />
                <Map />
            <Footer />
        </section>
    )
}

export default Kontakt
