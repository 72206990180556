import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Velveare() {
    return (
        <section>
            <Header />

            <section className="undersider">
                    <h2 className="undersider_h2">Velvære</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Velvaere.jpg'} loading="lazy" alt="Dette billede ser man en patient som er til velveare" className="underside_img"/>
                        </div>

                    <div className="underside_text">
                        <p className="underside_p">Ønsker du at være særlig god ved dig selv, og virkelig give din krop og sjæl en forkælelse udover det sædvanlige, skal du ikke snyde dig selv for en velværebehandling - Gestus Signatur. </p>
                        <p className="underside_p">I 90 minutter vil du blive forkælet med det allerbedste fra både den fysiurgiske massage og den Kranio Sakrale verden samt modtage en dybdegående ansigtsmassage.  En virkelig forkælelse for både din krop og sjæl.</p>
        
                    </div>
                </section>

            <Footer />
        </section>
    )
}

export default Velveare
