import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Ansigtsbehandling() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Efterfødselsmassage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Protraet.jpg'} loading="lazy" alt="Dette billede ser man en patient få ansigtsbehandling" className="underside_img"/>
                        </div>
                    <div className="underside_text">
                        <p className="underside_p">Efterfødselsmassage er en dybdegående fysiurgisk massage særligt
tilpasset dig som nybagt mor. Både graviditeten og den første tid efter er
særlig belastende for kroppen både fysisk og mentalt, og mange har
derfor behov for lidt hjælp til at få kroppen til at finde tilbage igen.</p>
                        <p className="underside_p">Efterfødselsmassage har særligt fokus på:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Afspænding – særligt med vægt på ryg, skuldre og nakke</li>
                            <li className="underside_li">Lindre bækkensmerter</li>
                            <li className="underside_li">Give velvære</li>
                            <li className="underside_li">Hjælpe livmoderen til at trække sig sammenr</li>
                            <li className="underside_li">Påvirke dit nervesystem til at udvikle mere oxytocin</li>
                            <li className="underside_li">Øge fordøjelsesprocessen</li>
                        </ul>
                        <p className="underside_p">Du er selvfølgelig mere end velkommen til at medbringe din baby til behandlingen.</p>
                       
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default Ansigtsbehandling