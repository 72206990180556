import React from 'react'
import Header from "../Components/Header/Header"
import OmGEstusComponet from "../Components/OmGestus/OmGestus"
import Footer from "../Components/Footer/Footer"

function OmGestus() {
    return (
        <section>
            <Header />

            <OmGEstusComponet />

            <Footer /> 

        </section>
    )
}

export default OmGestus
