import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function SaerligeBehov() {
    return (
        <section>
        <Header/>
            <section className="saerligebehov">
                <h2 className="saerligebehov_h2">Særlige behov eller andre hensyn</h2>
                    <div className="saerligebehov_images">
                        <img src={process.env.PUBLIC_URL + '/Img/SaerligeBehov.jpg'} loading="lazy" alt="Dette billede viser en figur af en rygsøjle og en figur af et skelet" className="saerligebehov_img"/>
                    </div>

                <div className="saerligebehov_text">
                    <p className="saerligebehov_p">I Kranio Sakral Terapien arbejder jeg med et meget blidt – næsten ikke mærkbart – tryk, som dog er særdeles effektivt. Denne blide behandling gør det muligt at jeg kan behandle særligt sensitive personer, som normalt ikke kan lide berøring.</p>
                    <p className="saerligebehov_p">Jeg har gennem mit arbejde stor erfaring med denne gruppe mennesker, både særlige sensitive, mennesker med udviklingshæmning eller andre med særlige udfordringer. Du skal derfor ikke holde dig tilbage med at kontakte mig hvis du har nogle særlige hensyn, som skal tages, for at behandlingen passer lige præcis til dig.</p>
                </div>
            </section>
        <Footer/>
    </section>
    )
}

export default SaerligeBehov