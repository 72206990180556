import React from 'react'
import { NavLink } from "react-router-dom"
import Header from "../../Components/Header/Header"
import Footer from "../../Components/Footer/Footer"

function Teenager() {
    return (
        <section>
        <Header/>
            <section className="undersider">
                <h2 className="undersider_h2">Teenagere</h2>
                    <div className="underside_images">
                        <img src={process.env.PUBLIC_URL + '/Img/Teenage.jpg'} loading="lazy" alt="På dette billede ses en teenage dreng få massage på ryggen" className="underside_img"/>
                    </div>
                <div className="underside_text">
                    <p className="underside_p">Rigtig mange unge bruger dagligt mange timer foran computeren. Dette er både i skoledagen hvor de unge sidder foran computeren i klassen, samt når de har fri og benytter computeren, ipad eller mobil og derved udsætter nakken og lænden for stor belastning med risiko for belastningssmerter i nakken og hovedpine til følge. Gestus Behandling har derfor skræddersyet nedenstående to behandlinger målrettet direkte til de unge. </p>

                        <div className="underside_knap">
                            <NavLink to="/gamer">Gamers</NavLink>
                            <NavLink to="/eksamensstresset">Eksamens stresset</NavLink>
                        </div>
                </div>
            </section>
        <Footer/>
    </section>
    )
}

export default Teenager