import React from 'react';
import { slide  as Menu } from 'react-burger-menu';
import { NavLink } from 'react-router-dom';

const props = () =>  {
  return (
    <Menu className="burgerMenu"  right>


        <NavLink  className="menu-item " to="/home">
            Forside
        </NavLink>

        <NavLink className="menu-item" to="/kranioSakralTerapi">
            Kranio Sakral Terapi
        </NavLink>

        <NavLink className="menu-item" to="/fysiurgiskmassage">
            Fysiurgisk massage
        </NavLink>

        <NavLink className="navbar__link" activeClassName="navbar__link--active" to="/Tilbyder">
            Jeg tilbyder
        </NavLink>
        {/* <div className="dropdown">
            <button className="dropbtn">Jeg tilbyder</button>
                <div className="dropdown-content">
                <NavLink to="/kraniosakralbehandling">Kranio Sakral behandling</NavLink>
                    <NavLink to="/fysiurgiskmassagebehandling">Massage</NavLink>
                    <NavLink to="/Babybehandling">Babybehandling</NavLink>
                    <NavLink to="/teenage">Teenagere</NavLink>
                    <NavLink to="/saerligeBehov">Særlige behov</NavLink>
                    <NavLink to="/ansigtsbehandling">Ansigtsmassage</NavLink>
                    <NavLink to="/velveare">Velvære</NavLink>
                    <NavLink to="/hjemmebehandling">Hjemmebehandling</NavLink>
                    <NavLink to="/gravidmassage">Gravidmassage</NavLink>
                    <NavLink to="/efterfodselsmassage">Efterfødselsmassage</NavLink>


                </div>
        </div> */}

        <NavLink className="menu-item" to="/kontakt"> 
            Kontakt
        </NavLink>

        <NavLink className="menu-item" to="/priser">
            Priser
        </NavLink>

        <NavLink className="menu-item" to="/omGestus">
            Om Gestus
        </NavLink>
    </Menu>
  );
};

export default props