import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function FysiurgiskMassage() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Fysiurgisk massage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/FysiurgiskMassage1.jpg'} loading="lazy" alt="På dette billede ser man en liggende patient få fysiurgisk massage" className="underside_img"/>
                        </div>

                    <div className="underside_text">
                        <p className="underside_p">Fysiurgiske massage handler om at afhjælpe lidelser i bevægeapparatet gennem massage. Den dybdegående massage løsner spændinger i muskulaturen, forebygger, behandler og lindre.</p>
                        <p className="underside_p">Massagen er med til frigive og løsne spændinger, muskelsmerter og/eller fastlåsninger i både muskler, led og væv. Massagen har særlig stor gavn og virkning på smerter som fx:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Hovedpine og migræne</li>
                            <li className="underside_li">Smerter i nakken og skulderen</li>
                            <li className="underside_li">Smerter i ryggen og lænden</li>
                            <li className="underside_li">Smerter i benene og lårene</li>
                            <li className="underside_li">Smerter i ballen – fx fra iskiasnerven</li>
                            <li className="underside_li">Eller blot som ren muskelafspænding og afslapning</li>
                        </ul>
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default FysiurgiskMassage