import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"
import { NavLink } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

function tilbyder() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Jeg tilbyder</h2>
                    <p className='underside-tilyder-text'>Her hos Gestus Behandling tilbyder jeg lang række behandlinger her i blandt</p>
                </section>
                    <div className="underside-flex">
                        <NavLink to="/kraniosakralbehandling" className='underside-flex-item'>
                            <h3>KranioSakral Behandling</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/fysiurgiskmassagebehandling" className='underside-flex-item'>
                            <h3>Massage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/fysiurgiskmassagebehandling" className='underside-flex-item'>
                            <h3>Fysiurgisk massagebehandling</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/Babybehandling" className='underside-flex-item'>
                            <h3>Babybehandling</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/teenage" className='underside-flex-item'>
                            <h3>Teenage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/saerligeBehov" className='underside-flex-item'>
                            <h3>Særlige behov</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/ansigtsbehandling" className='underside-flex-item'>
                            <h3>Ansigtsmassage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/velveare" className='underside-flex-item'>
                            <h3>Velvære</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/hjemmebehandling" className='underside-flex-item'>
                            <h3>Hjemmebehandling</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/gravidmassage" className='underside-flex-item'>
                            <h3>Gravidmassage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/efterfodselsmassage" className='underside-flex-item'>
                            <h3>Efterfødselsmassage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                        <NavLink to="/Organmassage" className='underside-flex-item'>
                            <h3>Organmassage</h3>
                            <BsArrowRight className='underside-flex-item-pil' />
                        </NavLink>
                    </div>
            <Footer/>
        </section>
    )
}

export default tilbyder