import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Ansigtsbehandling() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Ansigtsmassage</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/Ansigtsbehandling.jpg'} loading="lazy" alt="Dette billede ser man en patient få ansigtsbehandling" className="underside_img"/>
                        </div>
                    <div className="underside_text">
                        <p className="underside_p">Dybdegående økologisk ansigtsmassage</p>
                        <p className="underside_p">60 minutters skøn behandling med fokus på øget velvære, udrensning, reducering af aldringstegn, samt en skabelse af glød og liv i ansigtet.</p>
                        <p className="underside_p">Behandlingen vil gennem en opløftende og afstressende massage føre dig gennem følgende trin:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Afrensning af din hud med økologisk rensemælk</li>
                            <li className="underside_li">Ansigtsmassage med stimulering af hele 24 akupunkturpunkter, der gennem rolige og blide cirkelbevægelser virker stimulerende, balancerende og opstrammende på din hud</li>
                            <li className="underside_li">Dybdegående muskelmassage af dit ansigt hvor din energi, dit nervesystem og dit immunforsvar bearbejdes gennem faste strøg</li>
                            <li className="underside_li">Let lymfe drænage</li>
                            <li className="underside_li">Afsluttende påføring af økologisk dagcreme </li>
                            <li className="underside_li">Let massage af nakke- og halsmuskler</li>
                        </ul>
                        <p className="underside_p">En virkelig dejlig selvforkælelsesbehandling med 100% nydelse for selv den kræsne.</p>
                        <p className="underside_p">En behandling, der sætter gang i din egen krops energisystemer og kredsløb, fjerner affaldsstoffer, ilter dit væv og stimulere din produktion af kollagen og elastin</p>
                        <p className="underside_p">Du vil uden tvivl blive afhængig af denne afstressende forkælelse.</p>
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default Ansigtsbehandling