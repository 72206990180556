import React from 'react'
import Footer from "../Components/Footer/Footer"
import Header from "../Components/Header/Header"

function Home() {
    return (
        <section>
            <Header />
            <section className="forside_container">
 
            <div className="forside_images">
                <img src={process.env.PUBLIC_URL + '/Img/Rum3.jpg'} loading="lazy"  alt="Dette billede viser rummet hvor du bliver behandlet i" className="forside_img"/>

                <h3 className="forside_h3">Velkommen til Gestus Behandling</h3>
            </div>

             <div className="forside_text">
                <h3 className="forside_slogan">Livet er vidunderligt - men ikke altid med smerter.</h3>
                <p className="forside_firstP">Derfor må du aldrig acceptere at smerter tager fokuset.</p>
                <p className="forside_p">Gestus Behandling kan være med til at afhjælpe og lindre smerter som fx:</p>
               <ul className="forside_list">
                  <li className="forside_li">Hovedpine og migræne</li>
                    <li className="forside_li">Smerter i nakken og skulderen</li>
                    <li className="forside_li">Smerter i ryggen, lænden og bækkenet</li>
                    <li className="forside_li">Piskesmæld og hjernerystelse</li>
                    <li className="forside_li">Stress symptomer</li>
                    <li className="forside_li">Muskelspændinger</li>
                    <li className="forside_li">Kæbespændinger</li>
                    <li className="forside_li">mm</li>
                </ul>
                <p className="forside_lastP">Vil du høre om Gestus Behandling også kan hjælpe dig, så ring eller send mig en 
                <br/>
                sms på 2643 6760 for en uforpligtende snak om dine muligheder.
                 </p>

          </div> 
        </section> 

            <Footer /> 
         
        </section>
    )
}

export default Home