import React from 'react'

function Priser() {
    return (
        <section className="KontakCompoentSection">

            <h3 className="forside_h3">Kontakt Gestus Behandling</h3>

            <button className="kontaktRing">
                <a href="tel:+4526436760">+45 26 43 67 60</a>
            </button>

            <div className="forside_text">
                <p>Bookning af behandling sker på ovenstående telefonnummer.
                <br/>
                    Hvis jeg ikke tager telefonen, så læg en besked, eller send en sms, og jeg vil vende tilbage til dig hurtigst muligt.
                <br/>
                    Klinikken Gestus Behandling ligger i mit hjem på Brabrand Skovvej 86, Brabrand.
                    <br/>
                    <br/>
                    Der er mulighed for gratis parkering lige ved døren og kommer du med bus, holder bus nr. 12 lige udenfor døren og bus 4A, 11, 113 blot 600 meter væk.
                    Hvis du, i første omgang, blot ønsker at høre om Gestus Behandling er en mulighed for dig, er du altid velkommen til at ringe eller skrive for en uforpligtende snak.
                    <br/>
                    Gestus Behandling har fleksible åbningstider - både hverdage, aftener og weekender, så vi kan altid finde en tid, som passer ind i dit program.
                    Det er også muligt at skrive en mail til mig på gestusbehandling@gmail.com
</p>

            </div>

            
        </section>
    )
}

export default Priser

