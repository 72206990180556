import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function KranioSakralTerapi() {
    return (
        <section>
            <Header/>
                <section className="undersiderkst">
                    <h2 className="undersider_h2">Kranio Sakral Terapi</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/KST.jpg'} loading="lazy" alt="Dette billede ser man en patient få kranio sakral" className="underside_img"/>
                        </div>

                    <div className="underside_kst">
                        <b className="underside_p">Erfaringer viser at Kranio Sakral Terapi kan have en positiv effekt på lidelser som:</b>
                        <ul className="underside_list">
                            <li className="underside_li">Hovedpine og migræne</li>
                            <li className="underside_li">Piskesmæld og hjernerystelse (også af ældre dato)</li>
                            <li className="underside_li">Stress symptomer</li>
                            <li className="underside_li">Muskelspændinger</li>
                            <li className="underside_li">Ryg- og nakkesmerter</li>
                            <li className="underside_li">Bækkensmerter</li>
                            <li className="underside_li">Angst</li>
                            <li className="underside_li">Søvnbesvær</li>
                            <li className="underside_li">Uro i kroppen</li>
                            <li className="underside_li">Choktilstand</li>
                            <li className="underside_li">Kæbespænding</li>
                        </ul>
                    </div>


                    <div className="underside_text">
                    <p className="underside_p">Mange af mine klienter vælger også at få en Kranio Sakral behandling blot for at opnå en høj grad af afslappethed og følelse af velvære i kroppen. Her kan du med fordel kombinere behandlingen med en rygmassage til start, så din krop efterfølgende føler sig fuldstændigt afslappet. En sådan behandling vil typisk vare 60-90 minutter.</p>
                        <h3 className="underside_h3">
                            Hvad er Kranio Sakral Terapi?
                        </h3>
                        <p className="underside_p">Kranio Sakral Terapi er en meget blid, men dybtgående manuel behandlingsform.</p>
                        <p className="underside_p">Kranio Sakral Terapi udspringer fra en osteopatisk (manuel-medicinsk) metode og bygger på, at kroppen bliver “hørt og imødekommet” ved, at jeg med mine hænder, undersøger dig for mærkbare spændinger, bevægelser og rytmer i kroppen.</p>
                        <p className="underside_p">Denne respektfulde måde at møde kroppen på gør, at kroppen aktivt slipper sine bevidste og ubevidste spændinger, således, at vi sammen kan afhjælpe dine problemer eller smerter.</p>
                        <p className="underside_p">I behandlingen arbejdes der med at fjerne spændinger og ubalancer i det Kranio Sakrale system, som består af kraniets og ansigtets knogler, nakken, rygsøjlen, de store hinder, der beskytter hjerne og rygmarv, korsbenet, halebenet samt cerebrospinalvæsken.</p>
                        <p className="underside_p">Dette gøres for at skabe de bedste ”arbejdsbetingelser” for nervesystemet. Når nervesystemet fungerer optimalt, vil det have en positiv effekt på hele kroppen, herunder også hormon- og immunsystemet. Kranio-Sakral Terapi har derfor både en umiddelbar effekt, hvor væv afspændes og giver afslapning og velvære, men i lige så høj grad en effekt, som først opleves efter et par dage eller uger, når nervesystemet begynder at fungere bedre.</p>
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default KranioSakralTerapi
