import React from 'react'
import Header from "../Components/Header/Header"
import Footer from "../Components/Footer/Footer"

function Babybehandling() {
    return (
        <section>
            <Header/>
                <section className="undersider">
                    <h2 className="undersider_h2">Baby</h2>
                        <div className="underside_images">
                            <img src={process.env.PUBLIC_URL + '/Img/baby.jpg'} loading="lazy" alt="Dette billede ser man en baby som for en behandling" className="underside_img"/>
                        </div>
                    <div className="underside_text">
                        <p className="underside_p">Har I været igennem en svær fødsel eller graviditet, og har dit barn deraf fået spændinger i fx nakken, bækkenet eller ryggen, kan jeg blidt gennem nænsom behandling være med til at afspænde og afhjælpe dette. </p>
                        <p className='underside_p'>Spændingerne kan sidde både i nakken, bækkenet og ryggen, men også spændinger i kæben, kan give udfordringer for den lille baby i forbindelse med amning/flaske samt sprogudvikling.
                            Inden behandling starter jeg altid med at give din baby et kort babytjek, hvor jeg undersøger hvor spændingerne eller skævhederne sidder, og derefter vil 2-3 blide behandlinger typisk være nok.
                            Ubehandlede spændinger kan betyde, at dit barn ikke trives, græder meget, eller ikke er så aktivt som man kunne ønske sig. 
                            Mange henvender sig også til mig, blot for at få et babytjek, og være sikre på, at deres baby ikke har spændinger eller skævheder, og simpelthen blot ønsker et tjek af, at deres baby har de bedste betingelser i både muskulaturen, nervesystemet og bevægeapparatet for at udvikle sig bedst muligt og få den bedste start på livet.
                        </p>
                        <p className='underside_p'>I Gestus Behandling, har jeg særlig god erfaring med behandling af:</p>
                        <ul className="underside_list">
                            <li className="underside_li">Spændinger i muskulaturen og nervesystemet</li>
                            <li className="underside_li">Favoritside</li>
                            <li className="underside_li">Mavekneb</li>
                            <li className="underside_li">Søvnproblematikker</li>
                            <li className="underside_li">Uforklarlig gråd og utilpashed</li>
                            <li className="underside_li">Spændinger i kæben</li>
                        </ul>
                        <p className="underside_p">Til info tilbyder jeg også gravidmassage, babymassage og efterfødselsmassage, ligesom mødregruppe arrangementer hvor I ellers jeres børn på skift behandles, kan arrangeres. Kontakt mig på 2643 6760 for denne behandling.</p>
                    </div>
                </section>
            <Footer/>
        </section>
    )
}

export default Babybehandling