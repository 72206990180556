import React from 'react'

function Map() {
    return (
        <section>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d555.6144918818744!2d10.134979829249405!3d56.14918599879134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464c154ba41b1907%3A0x54ba25177d657f41!2sBrabrand%20Skovvej%2086%2C%208220%20Brabrand!5e0!3m2!1sda!2sdk!4v1607503827091!5m2!1sda!2sdk" width="100%" height="450" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </section>
    )
}

export default Map

